*, *::before, *::after {
  border-width: 0;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  color-scheme: light dark; /* both supported */
  --background: radial-gradient(ellipse at center, #fffeea 0%, #fffeea 35%, #b7e8eb 100%);
  --text-primary: black;
  --text-secondary: royalblue;
  --backgroun-list: #fffa;
  --button-colors-orange-200: #FBD38D;
  --button-colors-orange-300: #F6AD55;
}
[data-theme='light'] {
  --background: radial-gradient(ellipse at center, #fffeea 0%, #fffeea 35%, #b7e8eb 100%);
  --background-modal: white;
  --color-btn-theme-toggle: white;
  --button-colors-purple-500: #805AD5;
  --button-colors-purple-600: #6B46C1;
}
[data-theme='dark'] {
  --background: #202023;
  --background-modal: #202023;
  --color-btn-theme-toggle: black;
  --text-primary: white;
  --text-secondary: grey;
  --backgroun-list: #404040cc;
  --button-colors-orange-200: #FBD38D;
}
