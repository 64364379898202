html {
    height: 100%;
    touch-action: manipulation;
}
body,
html {
    margin: 0;
    padding: 0;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
        "Arial", sans-serif;
    color: var(--text-primary);
}

body {
    min-height: 100%;
    background: var(--background);
    -webkit-overflow-scrolling: touch;
}

.h4,
h4 {
    font-size: calc(1.275rem + 0.3vw);
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}

a {
    color: var(--text-primary);
    text-decoration: none;
}

main {
    position: relative;
    height: 100vh;
    min-height: 100%;
}

button {
    color: var(--text-primary);
    background-color: var(--background);
    float: right;
    transition: all 1s;
}

.theme-button,
.share-button {
    height: 2.5rem;
    width: 2.5rem;
    z-index: 99;
    cursor: pointer;
    border-radius: 0.375rem;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: transparent solid 2px;
    outline-offset: 2px;
    line-height: 1.2;
    font-weight: 600;
    transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform;
    transition-duration: 200ms;
    padding: 0px;
}
.theme-button svg,
.share-button svg {
    top: -1px;
    position: relative;
    width: 0.8em;
    height: 0.8em;
    vertical-align: middle;
}
.theme-button.dark,
.share-button.dark {
    color: var(--color-btn-theme-toggle);
    background-color: var(--button-colors-orange-200);
}
.theme-button.dark:hover,
.share-button.dark:hover {
    background-color: var(--button-colors-orange-300) !important;
}

.theme-button.light,
.share-button.light {
    color: var(--color-btn-theme-toggle);
    background-color: var(--button-colors-purple-500);
}
.theme-button.light:hover,
.share-button.light:hover {
    background-color: var(--button-colors-purple-600) !important;
}

.animated {
    opacity: 0;
    filter: blur(10px);
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
}

header {
    position: relative;
    display: grid;
    grid-area: header;
    grid-template-columns: 1fr;
    grid-template-rows: 96px 50px 0px;
    justify-items: center;
    align-items: center;
    justify-self: center;
    grid-column: 1 / -1;

    justify-content: center;
    align-content: center;

    text-align: center;
}

header > * {
    grid-column: 1 / -1;
    grid-row: 1;
}

header > img {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 6em;
    height: 6em;
    border-radius: 100%;
    /* border: 1px solid #fff; */
    box-shadow: rgb(28 32 93 / 24%) 0px 2px 8px 0px;
    filter: brightness(1.2) contrast(105%);
}

header > h1 {
    grid-row: 2 / 3;
    position: relative;
    z-index: 2;
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    color: var(--text-primary);
    padding: 0.3em 0.6em;
}

header > h2 {
    grid-row: 3 / 3;
    position: relative;
    z-index: 2;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    color: var(--text-primary);
    padding: 0.3em 0.6em;
}

ul > li > a > img {
    flex-shrink: 1;
}
ul > li > a > span {
    flex-grow: 1;
    margin-right: 30px;
}

.websiteList > li {
    background-color: var(--backgroun-list);
    border-radius: 10em;
    margin: 1.4em 0;
    box-shadow: rgb(41 41 41 / 24%) 0px 2px 8px 0px;
    height: 60px;
}

.black_mask {
    position: fixed;
    inset: 0px;
    z-index: -1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(0, 0, 0);
}

.background_mask {
    inset: 0px;
    z-index: -1;
}

.profilePictureBg {
    width: 100%;
    height: 100%;
    position: relative;
}

.profilePictureBg::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    /* background-image: url(https://d1fdloi71mui9q.cloudfront.net/KGEh93DrTkCST9yfCB5S_8RtTgO8R2LOW2ZYy); */
    background-image: url("/public/img/kentucky.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.25;
    filter: blur(50px);
}

.profilePictureBg::after {
    content: "";
    position: fixed;
    width: 100%;
    top: 0px;
    height: 100%;
    /* background-image: url(https://assets.production.linktr.ee/profiles/_next/static/images/img-noise-256x256-fb8404ad3939a43b615f4819230bbb18.png); */
    /* background-repeat: repeat; */
    /* opacity: 0.05; */
    /* mix-blend-mode: overlay; */
}
/*===================*/
/* BOUNCE OUT
/*===================*/

.bounce-out-on-hover {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.bounce-out-on-hover:hover,
.bounce-out-on-hover:focus,
.bounce-out-on-hover:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

section {
    grid-area: section;
}

footer {
    display: grid;
    align-items: end;
    opacity: 0.4;
    font-size: 10px;
    margin-top: 40px;
    grid-area: footer;
}

@import url(Utils.css);
@import url(components/shared/Button/Button.css);
@import url(components/shared/Modal/Modal.css);
